/*
 * @Author: zhulu
 * @Date: 2022-09-29 15:58:12
 * @Description: 入口页面
 * 页面切换之间保持布局的持久化
 * 切换页面时保持状态（state）
 * 使用 componentDidCatch 自定义错误处理
 * 向页面（pages）注入额外的数据
 * 添加全局 CSS
 */

import '@/resources/styles/global.scss';
import '@/application/utils/common-components-init';

import { useCategory } from '@common/application/hooks/use-category';
import { useInRN } from '@common/application/hooks/use-in-rn';
import { useUpdateCartCount } from '@common/application/store/cart';
import { pageHomeCategory } from '@common/application/store/category';
import {
  useHandleFailureFallback,
  useSetSSOAuthenticated,
  useSSO,
  useUpdateCurrentStore
} from '@common/application/store/common';
import {
  globalConfig,
  globalDefaultBreakpoint,
  globalIsInApp,
  globalIsRobot
} from '@common/application/store/global';
import { InitRecoilRoot } from '@common/application/store/global-recoil';
import { useUpdateUser, useUser } from '@common/application/store/user';
import type { AuthData } from '@common/application/utils/auth';
import { globalAuth } from '@common/application/utils/auth';
import { isRobot } from '@common/application/utils/common';
import { Cookie } from '@common/application/utils/cookie';
import { useUnauthorizedListener } from '@common/application/utils/event';
import modifyURL from '@common/application/utils/handle-canonical-url';
import { isGuest } from '@common/application/utils/is-guest';
import { ServerSide } from '@common/application/utils/server-side';
import LocalStorage from '@common/application/utils/storage';
import { AcceptCookie } from '@common/components/common/accept-cookie';
import { CssClassNameLoader } from '@common/components/common/css-class-name-loader';
import DownLoadNotice from '@common/components/common/download-notice';
import { Exceptional } from '@common/components/common/exceptional';
import { PageTitle } from '@common/components/common/page-title';
import {
  ACCESS_TOKEN_KEY,
  IS_CLIENT,
  ONLINE_STORE_CODE_STORAGE_KEY,
  WEBSITE_LANGUAGE
} from '@common/constants/common';
import env, { global } from '@common/constants/env';
import { LANGUAGE_VALUES } from '@common/constants/language';
import { checkUrlNeedLogin, PATH_NAME_MAP, ROUTE_MAP } from '@common/constants/route-map';
import checkAndSetUserSession from '@common/middleware/check-userssion';
import setCookieMiddleware from '@common/middleware/set-cookie';
import type { GlobalConfigType, language } from '@common/types/common';
import { useAsyncEffect } from 'ahooks';
import axios from 'axios';
import { get, isEmpty, isString } from 'lodash-es';
// eslint-disable-next-line import/no-extraneous-dependencies
import LogRocket from 'logrocket';
import type { AppContext, AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { appWithTranslation, i18n, useTranslation } from 'next-i18next';
import { parse } from 'next-useragent';
import Nprogress from 'nprogress';
import type { PropsWithChildren } from 'react';
import { useEffect, useMemo } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ErrorBoundary } from 'react-error-boundary';
import { RecoilEnv, useRecoilValue } from 'recoil';

interface Props<P = {}> extends AppProps<P> {
  globalConfig?: any;
}

if (process.env.NODE_ENV === 'development') {
  RecoilEnv.RECOIL_DUPLICATE_ATOM_KEY_CHECKING_ENABLED = false;
}

// 不需要注册的页面
const NOT_NEED_REGISTER = [ROUTE_MAP.TOPIC, ROUTE_MAP.SECONDARY_REGISTER];

/**
 * Generates a custom hook for handling router events.
 *
 * @return {void}
 */
const useHandleRouter = () => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeStart = () => {
      Nprogress.start();
    };

    const handleRouteChangeEnd = () => {
      // https://github.com/vercel/next.js/issues/33233
      // https://blog.csdn.net/SaRAku/article/details/136074758
      if (typeof window !== 'undefined' && globalAuth.inited) {
        window.history.replaceState(window.history.state, '', ' ');
      }
      Nprogress.done();
    };

    const handleRouteChangeError = (_e: any, path: string) => {
      Nprogress.done();
      if (!path) {
        return;
      }
      /**
       * 修复，购物车返回首页页面地址变化，但是没有切换页面
       */
      const homePath = ['/tc', '/en', '/cn'];
      const ePath = path.split('#')[0];
      if (homePath.includes(ePath)) {
        router.reload();
      }
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);
    router.events.on('routeChangeComplete', handleRouteChangeEnd);
    router.events.on('routeChangeError', handleRouteChangeError);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
      router.events.off('routeChangeComplete', handleRouteChangeEnd);
      router.events.off('routeChangeError', handleRouteChangeEnd);
    };
  }, [router.events, globalAuth.inited]);
};

const useInitConfig = (defaultConfig: GlobalConfigType, isInApp: boolean) => {
  const { accessToken, userSession, onlineStoreCode, extend } = defaultConfig;

  if (IS_CLIENT) {
    global.accessToken = accessToken;
    global.userSession = userSession;
    global.onlineStoreCode = onlineStoreCode;
    // 推广参数
    global.extend = extend;

    if (global.onlineStoreCode) {
      LocalStorage.set(ONLINE_STORE_CODE_STORAGE_KEY, global.onlineStoreCode);
    } else {
      LocalStorage.remove(ONLINE_STORE_CODE_STORAGE_KEY);
    }
    if (isInApp)
      // ios的监听RN传递过来的事件
      window.addEventListener('message', async (e) => {
        if (e.data.userSession !== userSession && isInApp) {
          await globalAuth.updateUserSession();
        }
      });
  }
};

const Container: React.FC<
  PropsWithChildren<{
    prefetch?: string[];
    pageSEO?: {
      title: string;
      keywords: string;
      description?: {
        key: string;
        options?: any;
      };
      isPlp?: boolean;
      useT?: boolean;
      isCampaign?: boolean;
    };
  }>
> = ({ prefetch, children, pageSEO }) => {
  const updateUser = useUpdateUser();
  const updateCartCount = useUpdateCartCount();
  const updateCurrentStore = useUpdateCurrentStore();
  const isInRn = useInRN();
  useHandleFailureFallback();

  const router = useRouter();

  const { setSettled } = useSetSSOAuthenticated();
  const { settled } = useSSO();

  const { user: memberInfo } = useUser();

  /**
   * 监听未登录事件
   */
  useUnauthorizedListener(() => {
    if (globalAuth.inited) {
      globalAuth.login();
    }
  });

  /**
   * 处理用户登录
   */
  useEffect(() => {
    if ((window as any).KeycloakInitError) {
      setSettled(false);
    }
    (window as any).KeycloakInitErrorCallback = () => {
      setSettled(false);
    };

    globalAuth.addListener('inited', () => {
      if (!globalAuth.keycloak?.authenticated) {
        setSettled(false);
      }

      if (typeof window !== 'undefined') {
        window.history.replaceState(window.history.state, '', ' ');
      }
    });

    globalAuth.addListener('login', async (data?: AuthData) => {
      if (data && data.firstFlag && !NOT_NEED_REGISTER.includes(router.route as any)) {
        router.push(ROUTE_MAP.SECONDARY_REGISTER);
        setSettled(true);
        return;
      }
      if (isInRn) {
        setSettled(true);
      }
      updateUser();
    });
    globalAuth.addListener('logout', () => {
      setSettled(false);
    });

    globalAuth.init();

    updateCurrentStore();
  }, []);

  useEffect(() => {
    if (settled) {
      updateCartCount();
      Nprogress.done();
    } else {
      Nprogress.start();
    }
  }, [settled]);

  useEffect(() => {
    if (memberInfo) {
      setSettled(true);
    }
  }, [memberInfo]);

  useEffect(() => {
    if (env.ENV === 'production' && memberInfo?.memberId) {
      LogRocket.init('qnunx3/dch-living-storefront-production');
    }

    if (env.ENV === 'uat' && memberInfo?.phone === '51201263') {
      LogRocket.init('qnunx3/dch-living-storefront-uat');
    }
  }, [memberInfo]);
  // 二次判断 未登录重定向至首页
  useEffect(() => {
    if (isGuest() && checkUrlNeedLogin(router.pathname) && !isInRn) {
      router.replace(ROUTE_MAP.HOME);
    }
  }, [settled]);

  /**
   * 用户登录后，更新分类信息
   */
  useCategory();

  const { t } = useTranslation();
  const { domain } = useRecoilValue(globalConfig);
  const pathName = get(PATH_NAME_MAP, router.route, 'homePage');
  const Header = useMemo(() => {
    const local = (i18n?.language as language) || 'tc';
    const p = router.asPath.slice(1);

    let canonicalURL = pageSEO?.isPlp
      ? modifyURL(`${domain}/${local}/${p}`)
      : `${domain}/${local}/${p}`;

    if (pathName === 'homePage') {
      canonicalURL = `${domain}/${local}`;
    }
    let description = isString(pageSEO?.description) ? pageSEO?.description : '';
    if (pageSEO?.description && pageSEO.description?.key) {
      description = t(pageSEO.description?.key, pageSEO.description?.options);
    }

    const title =
      pageSEO?.useT && pageSEO?.title && !pageSEO?.isCampaign ? t(pageSEO?.title) : pageSEO?.title;
    const keyword = pageSEO?.useT && pageSEO?.keywords ? t(pageSEO?.keywords) : pageSEO?.keywords;

    const pageTitle =
      title && title !== t('common:pageTitle.homePage')
        ? t('common:pageTitle.productListingPage', {
            categoryName: title
          })
        : t('common:pageTitle.homePage');
    return (
      <>
        <title>{pageTitle}</title>
        {!isEmpty(pageSEO?.title) && <meta name="title" content={title} />}
        {!isEmpty(keyword) && <meta name="keywords" content={keyword} />}
        {!isEmpty(description) && <meta name="description" content={description} />}
        <meta name="robots" content="INDEX, FOLLOW" />
        <link rel="canonical" href={canonicalURL} />
      </>
    );
  }, [router.asPath]);
  return (
    <>
      <Head>
        {Header}
        {prefetch &&
          prefetch.map((item: string) => <link key={item} rel="prefetch" href={item} as="image" />)}
      </Head>

      {settled && children}
    </>
  );
};

const App = (
  props: Props<{
    recoilState: AnyObject;
    redirect: {
      statusCode: number;
      message: string;
      image: string;
      hideButton: boolean;
    };
    prefetch?: string[];
    pageSEO?: {
      title: string;
      keywords: string;
      description?: {
        key: string;
        options?: any;
      };
      isPlp?: boolean;
      useT?: boolean;
      isCampaign?: boolean;
    };
  }> & {
    globalState: {
      globalConfig: GlobalConfigType;
      globalIsInApp: boolean;
    };
    referer: string | undefined;
  }
) => {
  const { Component, pageProps, globalState } = props;
  const { recoilState = {}, redirect, pageSEO, ...restProps } = pageProps;

  const router = useRouter();

  useHandleRouter();
  const notFound = router.pathname.startsWith('/404');
  useInitConfig(globalState.globalConfig, globalState.globalIsInApp);

  /**
   * 初始化cookies中多语言储存
   */
  useAsyncEffect(async () => {
    await axios.get('/api/setWebsiteLanguage', {
      headers: {
        [WEBSITE_LANGUAGE]: i18n?.language ?? LANGUAGE_VALUES.TC
      }
    });
  }, []);

  const pageComponent = isEmpty(redirect) ? (
    <>
      <PageTitle />

      <DownLoadNotice />

      <Component {...restProps} />
      <AcceptCookie />
    </>
  ) : (
    <>
      <Exceptional {...redirect} />
      <AcceptCookie />
    </>
  );

  return (
    <InitRecoilRoot
      state={{
        ...recoilState,
        ...globalState
      }}
    >
      <CssClassNameLoader loadClassName="w-86 pc:w-132" />
      {notFound ? (
        pageComponent
      ) : (
        <Container prefetch={pageProps.prefetch} pageSEO={pageSEO}>
          {pageComponent}
        </Container>
      )}
    </InitRecoilRoot>
  );
};

App.getInitialProps = async (appContext: AppContext) => {
  const { ctx } = appContext;

  // 将中间件添加到 Next.js 的处理函数中
  if (ctx.req && ctx.res) {
    await setCookieMiddleware(ctx);
  }

  // 处理accessToken和userssion
  const accessToken = ctx ? Cookie.get(ctx, ACCESS_TOKEN_KEY) : '';
  const userSession = ctx ? await checkAndSetUserSession(ctx) : '';

  const categoryList = await ServerSide.getCategoryList(ctx as any);

  const userAgent = IS_CLIENT ? window.navigator.userAgent : ctx.req?.headers['user-agent'] || '';
  const isInApp = userAgent.includes('dchliving');

  // 处理门店信息
  const onlineStoreCode = Cookie.get(ctx, ONLINE_STORE_CODE_STORAGE_KEY);

  function getDefaultBreakpoint() {
    return parse(userAgent).isMobile ? 'mobile' : 'pc';
  }
  const header = ctx?.req?.headers as any;
  const baseUrl = `${header['x-forwarded-proto']}://${header.host}`;

  const globalState = {
    [globalDefaultBreakpoint.key]: getDefaultBreakpoint(),
    [globalIsInApp.key]: isInApp,
    [globalConfig.key]: {
      onlineStoreCode,
      userSession,
      accessToken,
      domain: baseUrl,
      extend: ctx?.query?.extend
    },
    [globalIsRobot.key]: isRobot(userAgent),
    [pageHomeCategory.key]: categoryList
  };

  return {
    referer: ctx.req?.headers.referer,
    globalState
  };
};

// 捕获所有 js 异常
const AppErrorBoundary: typeof App = (props) => {
  return (
    <ErrorBoundary
      fallback={
        <InitRecoilRoot>
          <Exceptional statusCode={501} />
          <AcceptCookie />
        </InitRecoilRoot>
      }
    >
      <App {...props} />
    </ErrorBoundary>
  );
};

AppErrorBoundary.getInitialProps = App.getInitialProps;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default appWithTranslation(AppErrorBoundary);
